import React from 'react';
import styled from 'styled-components';

const Code = styled.code`
  display: block;
  white-space: pre-wrap;
  text-align: initial;
`;

interface SummaryProps {
  rule?: any;
}

/**
 * A placeholder component to stringify json
 * @param {json} rule - rule to stringify
 * @constructor
 */
export default function TextViewer({ rule = {} }: SummaryProps = {}): JSX.Element {
  return <Code>{JSON.stringify(rule, null, 2)}</Code>;
}
